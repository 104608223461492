import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { useSelector } from 'react-redux';
import { getLocale } from '@myci/intl';
import { BlogArticleCard, BlogArticleSection, BlogHeroSection, Faq } from '@myci/module-blog';

import m from '../../messages';
import { PublicLayout } from '../../layouts';

interface BlogPostProps {
	excerpt: string;
	frontmatter: {
		locale: string;
		slug: string;
		thumbnail: IGatsbyImageData;
		title: string;
	};
	id: number;
}

interface BlogPageProps {
	allPosts: Record<'posts', BlogPostProps[]>;
	hero: IGatsbyImageData;
}

const Blog = ({ data }: PageProps<BlogPageProps>) => {
	const { allPosts, hero } = data;
	const currentLocale = useSelector(getLocale);

	const localizedArticles = allPosts?.posts.filter(
		post => post.frontmatter.locale === currentLocale
	);

	const articles = localizedArticles.map(post => (
		<BlogArticleCard
			title={post.frontmatter.title}
			slug={`/blog/${post.frontmatter.slug}`}
			subtitle={post.excerpt}
			thumbnailImageData={post.frontmatter.thumbnail}
			key={post.id}
		/>
	));

	return (
		<PublicLayout header={<BlogHeroSection title={m.blog_title} backgroundData={hero} />}>
			<section className="container">
				<BlogArticleSection cards={articles} />
				<section id="faq">
					<Faq title={m.blog_faq} />
				</section>
			</section>
		</PublicLayout>
	);
};

export default Blog;

export const query = graphql`
	query {
		hero: file(relativePath: { eq: "blog/images/hero.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
			}
		}
		allPosts: allMdx {
			posts: nodes {
				id
				excerpt(pruneLength: 120)
				frontmatter {
					title
					locale
					slug
					thumbnail {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
						}
					}
				}
			}
		}
	}
`;
